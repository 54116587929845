<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Skoleeksamen burde være normen.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              Siden 2019 har hjemmeeksamen vært normen på vurderingsform hos UIA. Det er delte meninger om hjemmeeksamen
              er bedre enn skoleeksamen, det kan også sies at andre vurderingsformer er foretrukket som for eksempel mappeeksamen.
            </p>
            <p>
              Spørsmålet handler da om vi skal gå tilbake til skoleeksamen som vurderingsformen og ikke utvikle andre typer eksamen?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>